import React from "react"
import { Link } from "gatsby"
import StandardLayout from "../layouts/standard"
import PageCover from "../components/page-cover"
import decorativeLaminates from "../images/dnd/dl.png"
import specialSurfaces from "../images/dnd/special-surfaces.png"
import stylamIcon from "../images/dnd/stylam-icon.png"
import puCoating from "../images/dnd/pu-coating.png"
import coverImage from "../images/about/our-mission/our-mission-cover.jpg"
import DecorativeLaminates from "../components/DecorativeLaminates"
import SpecialSurfaces from "../components/SpecialSurfaces"
import PULacquer from "../components/PULacquer"
import SolidSurface from "../components/SolidSurface"
import CompactLaminates from "../components/CompactLaminates"

export default function DealersAndDistributors(props) {
  const ssUrl = "/products/special-surfaces/"
  const lcUrl = "/products/lacquer-coating/"
  const solsUrl = "/products/solid-surface/"
  const comlUrl = "/products/compact-laminates/"
  return (
    <StandardLayout title="Dealers and Distributors">
      <div>
        <PageCover
          coverImage={coverImage}
          title="DEALERS AND DISTRIBUTORS"
          description=""
        ></PageCover>
      </div>
      <section className="uk-section">
        <div className="uk-container uk-margin">
          <div>
            <h1 className="uk-h1 color-primary uk-text-uppercase uk-text-center">
              LAMINATES
            </h1>
            {/* <div className="uk-margin">
             
            </div> */}
            {/* <button className="uk-button uk-button-secondary">
              Learn More
            </button> */}
          </div>
        </div>

        <DecorativeLaminates />
        <SpecialSurfaces />

        <PULacquer />
        <SolidSurface />
        <CompactLaminates />
      </section>
    </StandardLayout>
  )
}
